import { error } from 'console';
import { CategoryApisActionTypes } from './constants';
// import { Actions } from './actions';

const INIT_STATE = {
    data: null,
    error: null,
};

type CategoryApisActionType = {
    type:
        | CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA
        | CategoryApisActionTypes.CATEGORIES_API_RESPONSE_SUCCESS
        | CategoryApisActionTypes.CATEGORIES_API_RESPONSE_ERROR;
    payload: {
        actionType?: string;
        data: any;
        error: string;
    };
};

const CategoryApis = (state = INIT_STATE, action: CategoryApisActionType) => {
    switch (action.type) {
        case CategoryApisActionTypes.CATEGORIES_API_RESPONSE_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };

        case CategoryApisActionTypes.CATEGORIES_API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return { ...state };
    }
};

export default CategoryApis;
