import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';
// setting
import CategoryApis from './categoryApis/reducers';
import OrdersApis from './ordersApis/reducers';
import ContentApis from './contentApis/reducers';

export default combineReducers({
    Auth,
    Layout,
    PageTitle,
    CategoryApis,
    ContentApis,
    OrdersApis
});
