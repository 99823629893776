import ReactDOM from 'react-dom';

import './i18n';

import App from './App';
import './global.css';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from 'react-toast-notifications';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

const ToastProviderWrapper: React.FC = ({ children }) => {
    return (
        <ToastProvider placement="top-right" autoDismissTimeout={5000}>
            {children}
        </ToastProvider>
    );
};

ReactDOM.render(
    <ToastProviderWrapper>
        <Provider store={configureStore({})}>
            <App />
        </Provider>
    </ToastProviderWrapper>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
