import { error } from 'console';
import { ContentApisActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    error: null,
};

type ContentApisActionType = {
    type:
        | ContentApisActionTypes.GET_CONTENT_APIS_DATA
        | ContentApisActionTypes.CONTENT_API_RESPONSE_SUCCESS
        | ContentApisActionTypes.CONTENT_API_RESPONSE_ERROR;
    payload: {
        actionType?: string;
        data: any;
        error: string;
    };
};

const ContentApis = (state = INIT_STATE, action: ContentApisActionType) => {
    switch (action.type) {
        case ContentApisActionTypes.CONTENT_API_RESPONSE_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case ContentApisActionTypes.CONTENT_API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return { ...state };
    }
};

export default ContentApis;
