import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { ContentApisActionTypes } from './constants';
import { contentApiResponseSuccess, contentApiResponseError } from './actions';
import { apiBaseURL } from '../../ApiBaseURL';

function* contentApis(): SagaIterator {
    try {
        const myHeaders = new Headers();
        const response = yield call(fetch, `${apiBaseURL}/content`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        });
        console.log(response, 'response--content');
        const data = yield response.json();
        yield put(contentApiResponseSuccess(ContentApisActionTypes.GET_CONTENT_APIS_DATA, data));
    } catch (error: any) {
        console.log(error, 'error----content');
        yield put(contentApiResponseError(ContentApisActionTypes.GET_CONTENT_APIS_DATA, 'Something Went Wrong'));
    }
}

export function* watchContentApis() {
    yield takeEvery(ContentApisActionTypes.GET_CONTENT_APIS_DATA, contentApis);
}

function* contentApisSaga() {
    yield all([fork(watchContentApis)]);
}

export default contentApisSaga;
