import { error } from 'console';
import { OrdersApisActionTypes } from './constants';
// import { Actions } from './actions';

const INIT_STATE = {
    data: null,
    error: null,
};

type OrdersApisActionType = {
    type:
        | OrdersApisActionTypes.GET_ORDERS_APIS_DATA
        | OrdersApisActionTypes.ORDERS_API_RESPONSE_SUCCESS
        | OrdersApisActionTypes.ORDERS_API_RESPONSE_ERROR;
    payload: {
        actionType?: string;
        data: any;
        error: string;
    };
};

const OrdersApis = (state = INIT_STATE, action: OrdersApisActionType) => {
    switch (action.type) {
        case OrdersApisActionTypes.ORDERS_API_RESPONSE_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };

        case OrdersApisActionTypes.ORDERS_API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return { ...state };
    }
};

export default OrdersApis;
