import { Action } from 'redux';
import { OrdersApisActionTypes } from './constants';

export type OrdersApisActionType = {
    type:
        | OrdersApisActionTypes.GET_ORDERS_APIS_DATA
        | OrdersApisActionTypes.ORDERS_API_RESPONSE_SUCCESS
        | OrdersApisActionTypes.ORDERS_API_RESPONSE_ERROR;
    payload: {} | string;
};

export const ordersApiResponseSuccess = (actionType: string, data: any): OrdersApisActionType => ({
    type: OrdersApisActionTypes.ORDERS_API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const ordersApiResponseError = (actionType: string, error: any): OrdersApisActionType => ({
    type: OrdersApisActionTypes.ORDERS_API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getOrdersApis = (): OrdersApisActionType => ({
    type: OrdersApisActionTypes.GET_ORDERS_APIS_DATA,
    payload: {},
});
