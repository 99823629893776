// apicore
import { APICore } from '../../helpers/api/apiCore';

// constants
import { AuthActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    loading: false,
    updatedRecordArray: [],
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.UPDATEDRECORDARRAY
        | AuthActionTypes.PAGENUMBER;
    payload: {
        actionType?: string;
        data?: UserData | {};
        error?: string;
        ids: string[];
    };
};

type PageData = {
    current: number;
    previous: number;
};

type RecordData = {
    ids: string[];
    status: string;
};

type State = {
    user?: UserData | {};
    loading?: boolean;
    value?: boolean;
    pageNumberData?: PageData | { current: 0; previous: 0 };
    updatedRecordArray: RecordData[] | [];
};

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        resetPasswordSuccess: action.payload.data,
                        loading: false,
                        passwordReset: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        passwordReset: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.PAGENUMBER:
            return { ...state, pageNumberData: action.payload };
        case AuthActionTypes.UPDATEDRECORDARRAY:
            console.log(action.payload, 'action.payload');
            console.log(
                state?.updatedRecordArray ? [...state?.updatedRecordArray, action.payload] : [action.payload],
                'IN REDUCER'
            );

            if (state?.updatedRecordArray && state?.updatedRecordArray?.length > 0) {
                const equals = (a: string[], b: string[]) => JSON.stringify(a) === JSON.stringify(b);
                const temp: any = [];
                var isAdded = false;
                state?.updatedRecordArray?.map((previousRecords) => {
                    console.log(previousRecords?.ids, 'previousRecords?.ids');
                    console.log(action.payload?.ids, 'action.payload?.ids');
                    console.log(
                        equals(previousRecords?.ids, action.payload?.ids),
                        'equals(previousRecords?.ids, action.payload?.ids)'
                    );
                    if (equals(previousRecords?.ids, action.payload?.ids)) {
                        temp.push(action.payload);
                        isAdded = true;
                    } else {
                        temp.push(previousRecords);
                    }
                });
                console.log(isAdded ? [...temp] : [...temp, action.payload], 'UPDATED OBJECT IN REDUX');
                return {
                    ...state,
                    updatedRecordArray: isAdded ? [...temp] : [...temp, action.payload],
                };
            } else {
                return {
                    ...state,
                    updatedRecordArray: [action.payload],
                };
            }

        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userLoggedIn: false,
                passwordReset: false,
                passwordChange: false,
                resetPasswordSuccess: null,
            };
        default:
            return { ...state };
    }
};

export default Auth;
