import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { apiBaseURL } from '../../ApiBaseURL';

// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// helpers
import { login as loginApi, logout as logoutApi, forgotPassword as forgotPasswordApi } from '../../helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';

// constants
import { AuthActionTypes } from './constants';

type UserData = {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
// function* login({ payload: { email, password }, type }: UserData): SagaIterator {
//     try {
//         const response = yield call(loginApi, { email, password });
//         const user = response.data;
//         // NOTE - You can change this according to response format from your api
//         api.setLoggedInUser(user);
//         setAuthorization(user['token']);
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
//     } catch (error: any) {
//         yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//         api.setLoggedInUser(null);
//         setAuthorization(null);
//     }
// }

function* login({ payload: { email, password }, type }: UserData): SagaIterator {
    try {
        const myHeaders = new Headers();
        const response = yield call(fetch, `${apiBaseURL}/auth/login`, {
            method: 'POST',
            headers: {
                ...myHeaders,
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            redirect: 'follow',
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const user = yield response.json();

        if (user.hasOwnProperty('error')) {
            yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, user.message));
            api.setLoggedInUser(null);
            setAuthorization(null);
        } else {
            console.log('no error   ');
            api.setLoggedInUser(user);
            setAuthorization(user['token']);
            yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        }
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'Something went wrong'));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchForgotPassword)]);
}

export default authSaga;
