import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { CategoryApisActionTypes } from './constants';
import { categoryApiResponseSuccess, categoryApiResponseError } from './actions';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// import { fetchCategorySuccess, fetchCategoryFailure } from './actions';
import { apiBaseURL } from '../../ApiBaseURL';
import { RootState } from '../store';
// import { FreeApisActionTypes } from '../freeApis/constants';

function* categoryApis(): SagaIterator {
    try {
        const myHeaders = new Headers();
        // myHeaders.append(
        //     'Authorization',
        //     `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0YjE2ZTlmMjIxOTllYzcwNjRjOGE5YSIsImlhdCI6MTY4OTc3MjY3MCwiZXhwIjoxNjkwMDMxODcwfQ.ZG_5W6gMJ_isXra36u8uE3tYjiL4z1TUB2zF4m7etlw`
        // );

        const response = yield call(fetch, `${apiBaseURL}/category`, {
            method: 'GET',
            // mode: 'no-cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: myHeaders,
            redirect: 'follow',
        });
        const data = yield response.json();
        yield put(categoryApiResponseSuccess(CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA, data));
    } catch (error: any) {
        console.log(error, 'error---');
        yield put(categoryApiResponseError(CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA, 'Something Went Wrong'));
    }
}

export function* watchCategoryApis() {
    yield takeEvery(CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA, categoryApis);
}

function* categoryApisSaga() {
    yield all([fork(watchCategoryApis)]);
}

export default categoryApisSaga;
