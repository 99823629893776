import { Action } from 'redux';
import { CategoryApisActionTypes } from './constants';

export type CategoryApisActionType = {
    type:
        | CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA
        | CategoryApisActionTypes.CATEGORIES_API_RESPONSE_SUCCESS
        | CategoryApisActionTypes.CATEGORIES_API_RESPONSE_ERROR;
    payload: {} | string;
};

export const categoryApiResponseSuccess = (actionType: string, data: any): CategoryApisActionType => ({
    type: CategoryApisActionTypes.CATEGORIES_API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const categoryApiResponseError = (actionType: string, error: any): CategoryApisActionType => ({
    type: CategoryApisActionTypes.CATEGORIES_API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCategoryApis = (): CategoryApisActionType => ({
    type: CategoryApisActionTypes.GET_CATEGORIES_APIS_DATA,
    payload: {},
});
