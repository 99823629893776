import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import categoryApisSaga from './categoryApis/saga';
import contentApisSaga from './contentApis/saga';
import ordersApisSaga from './ordersApis/saga';

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), categoryApisSaga(), contentApisSaga(), ordersApisSaga()]);
}
