import { ContentApisActionTypes } from './constants';

export type ContentApisActionType = {
    type:
        | ContentApisActionTypes.GET_CONTENT_APIS_DATA
        | ContentApisActionTypes.CONTENT_API_RESPONSE_SUCCESS
        | ContentApisActionTypes.CONTENT_API_RESPONSE_ERROR;
    payload: {} | string;
};

export const contentApiResponseSuccess = (actionType: string, data: any): ContentApisActionType => ({
    type: ContentApisActionTypes.CONTENT_API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const contentApiResponseError = (actionType: string, error: any): ContentApisActionType => ({
    type: ContentApisActionTypes.CONTENT_API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getContentApis = (): ContentApisActionType => ({
    type: ContentApisActionTypes.GET_CONTENT_APIS_DATA,
    payload: {},
});
